body{
  box-sizing: border-box;
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: #F2F2F2;
}
.main-logo{
  text-align: center;
  margin: 40px auto;
}
.main-body {
  padding: 40px;
  max-width: 600px;
  width: 100%;
  background: white;
  border-color: #004967;
  border-style: solid;

  border-radius: 32px;
  text-align: center;
  margin: auto auto 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-text{
  font-size: 36px;
  color: black;
}
.apps{
  text-align: center;
  margin: 40px;
}
.app-text{
  margin-bottom:24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
}